import axios from "axios";
import { QuestionViewRanking } from "@/types/question_view_ranking";
export type GetReportParams = {
  start_date: string;
  end_date: string;
  order: string;
}
export class ViewRankingRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = ViewRankingRepository.DOMAIN + "v1/projects/" + this.projectId + "/questions/view_ranking/";
  }
  async getReport(params: GetReportParams): Promise<QuestionViewRanking[]> {
    return axios.get(this.url , { params: params }).then((response) => {
      return response.data.questions;
    });
  }
}
