import { ref } from "vue";
import { useRoute } from 'vue-router'
import { QuestionViewRanking } from "@/types/question_view_ranking";
import { ViewRankingRepository, GetReportParams } from '@/repositories/ViewRankingRepository'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'

export const QuestionViewRankingLogic = () => {
    const viewRankings = ref<QuestionViewRanking[]>([])
    const viewRankingsErrors = ref("");
    const viewRnakingsLoading = ref(false);
    const route = useRoute();

    const getReport = async (params?: GetReportParams) => {
        if (params == undefined) {
            params = {start_date: "", end_date: "", order: "desc"}
        }
        viewRnakingsLoading.value = true
        const projectId = Number(route.params.project_id);
        const repository: ViewRankingRepository = new ViewRankingRepository(projectId);
        try {
            viewRankings.value = await repository.getReport(params);
            viewRnakingsLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "閲覧数履歴の読み込みに失敗しました。<br>再読み込みしてお試しください");
        }
    };
    return {
        viewRankings,
        getReport,
        viewRankingsErrors,
        viewRnakingsLoading,
    }
}


